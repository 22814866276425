import { Gender, ProgramEventType, Venue } from '@citruscamps/citrus-client'
import { DateTime } from 'luxon'
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring'
import { EventFilterProps } from '../hooks/useFetchEvents'
import { FilterDateRange } from '../interfaces/filter-range'

type PageViewType = 'list' | 'calendar'

interface IFilterProps {
  programId?: string
  venues: Venue[]
  hasOnline: boolean
  view: PageViewType
  query: ParsedUrlQuery
}

export const getInitialFilterProps = ({
  programId,
  venues,
  hasOnline,
  query,
  view,
}: IFilterProps): EventFilterProps => {
  const filter: EventFilterProps = {
    program_id: programId,
    ...toFilterFromQuery(query),
  }
  if (view === 'list') {
    filter.scheduled = undefined
  } else if (view === 'calendar') {
    filter.tags = undefined
    const scheduled: FilterDateRange = {}
    const selectedDateTime: DateTime = filter?.scheduled?.gte
      ? DateTime.fromJSDate(new Date(filter.scheduled.gte))
      : DateTime.now().startOf('day')

    if (DateTime.now().startOf('day') <= selectedDateTime) {
      scheduled.gte = selectedDateTime.startOf('day').toJSDate()
      scheduled.lte = selectedDateTime.endOf('day').toJSDate()
    } else {
      scheduled.gte = DateTime.now().startOf('day').toJSDate()
      scheduled.lte = DateTime.now().endOf('day').toJSDate()
    }
    filter.scheduled = scheduled
  }
  return filter
}

export const toFilterFromQuery = (query?: any): EventFilterProps => {
  const item: EventFilterProps = {}
  if (!query) {
    return item
  }
  if (query.program_id)
    item.program_id =
      typeof query.program_id === 'string'
        ? query.program_id
        : query.program_id
        ? query.program_id[0]
        : ''
  if (query.type)
    item.type = ((typeof query.type === 'string'
      ? query.type
      : query.type
      ? query.type[0]
      : undefined) || 'camp') as ProgramEventType
  if (query.tags)
    item.tags =
      typeof query.tags === 'string'
        ? [...query.tags.split(',')]
        : query.tags
        ? query.tags
        : undefined
  if (query.place_name)
    item.place_name =
      typeof query.place_name === 'string'
        ? query.place_name
        : query.place_name
        ? query.place_name[0]
        : ''
  if (query.age)
    item.age =
      typeof query.age === 'string'
        ? Number(query.age)
        : query.age
        ? Number(query.age[0])
        : undefined
  if (query.gender)
    item.gender =
      typeof query.gender === 'string'
        ? (query.gender as Gender)
        : query.gender
        ? (query.gender[0] as Gender)
        : undefined
  if (query.online_event)
    item.is_online_event =
      typeof query.online_event !== 'undefined' ? query.online_event === 'true' : undefined
  if (query.scheduled_lt) {
    item.scheduled = {
      ...item.scheduled,
      lt:
        typeof query.scheduled_lt === 'string'
          ? new Date(query.scheduled_lt)
          : query.scheduled_lt
          ? new Date(query.scheduled_lt[0])
          : undefined,
    }
  }
  if (query.scheduled_gt) {
    item.scheduled = {
      ...item.scheduled,
      gt:
        typeof query.scheduled_gt === 'string'
          ? new Date(query.scheduled_gt)
          : query.scheduled_gt
          ? new Date(query.scheduled_gt[0])
          : undefined,
    }
  }
  if (query.scheduled_lte) {
    item.scheduled = {
      ...item.scheduled,
      lte:
        typeof query.scheduled_lte === 'string'
          ? new Date(query.scheduled_lte)
          : query.scheduled_lte
          ? new Date(query.scheduled_lte[0])
          : undefined,
    }
  }
  if (query.scheduled_gte) {
    item.scheduled = {
      ...item.scheduled,
      gte:
        typeof query.scheduled_gte === 'string'
          ? new Date(query.scheduled_gte)
          : query.scheduled_gte
          ? new Date(query.scheduled_gte[0])
          : undefined,
    }
  }
  if (query.min_age)
    item.min_age =
      typeof query.min_age === 'string'
        ? Number(query.min_age)
        : query.min_age
        ? Number(query.min_age[0])
        : undefined
  if (query.max_age)
    item.max_age =
      typeof query.max_age === 'string'
        ? Number(query.max_age)
        : query.max_age
        ? Number(query.max_age[0])
        : undefined
  return item
}

export const toQueryFromFilter = (item?: EventFilterProps): ParsedUrlQueryInput => {
  const query: ParsedUrlQueryInput = {}
  if (item?.program_id) query.program_id = item.program_id
  if (item?.type) query.type = item.type || 'camp'
  if (item?.tags) query.tags = item.tags
  if (item?.place_name) query.place_name = item.place_name
  if (item?.age) query.age = item.age
  if (item?.min_age) query.min_age = item.min_age
  if (item?.max_age) query.max_age = item.max_age
  if (item?.gender) query.gender = item.gender
  if (item?.place_name) query.place_name = item.place_name
  if (item?.is_online_event) query.online_event = item.is_online_event
  if (item?.scheduled?.lt) query.scheduled_lt = item.scheduled.lt?.toJSON()
  if (item?.scheduled?.gt) query.scheduled_gt = item.scheduled.gt?.toJSON()
  if (item?.scheduled?.lte) query.scheduled_lte = item.scheduled.lte?.toJSON()
  if (item?.scheduled?.gte) query.scheduled_gte = item.scheduled.gte?.toJSON()
  return query
}
