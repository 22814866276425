import React from 'react'

interface IProps {
  size?: 'sm' | 'md'
}

export const CardSkeleton = ({ size = 'md' }: IProps) => (
  <div className="card event-card shadow border-0" style={{ borderRadius: '1rem' }}>
    {size === 'md' && <div className={`card-img-top animate`}></div>}
    <div className="card-body">
      <div className="row">
        <div className={`${size === 'sm' ? 'col' : 'col-4 col-sm-3'} text-center`}>
          <p className="text-uppercase mb-0 animate"></p>
          <p className="mb-0 h4 animate"></p>
        </div>
        <div
          className={`col-8 col-sm-9${size === 'sm' ? ' d-none d-sm-block' : ''}`}
          style={{ minHeight: '6.5rem' }}
        >
          <div className="card-title h5 mb-2 animate"></div>
          <div className="h1 mb-2 animate"></div>
        </div>
      </div>
    </div>
  </div>
)
