import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

export function useSearchParams<T>(initialValue?: T): [ParsedUrlQuery | T, (t: T) => void] {
  const { pathname, query, asPath, ...router } = useRouter()
  const value = query || initialValue
  const params: any = Object.entries(query).reduce(
    (p, [key, val]) => (pathname.includes(key) ? { ...p, [key]: val } : p),
    {},
  )
  const setValue = (keyValue?: T): void => {
    if (!keyValue || Object.keys(keyValue).filter((key) => key !== 'program_id').length === 0)
      return
    router.replace?.(
      {
        pathname,
        query: { ...params, ...keyValue },
      },
      undefined,
      {
        shallow: true,
      },
    )
  }
  if (!asPath.split('?')[1] && !!initialValue) {
    setValue(initialValue)
    return [initialValue, setValue]
  }
  return [value || ({} as ParsedUrlQuery), setValue]
}
