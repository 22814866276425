import { ParsedUrlQueryInput } from 'querystring'
import { Dispatch, useState } from 'react'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  ErrorResponse,
  ExploreApi,
  ExploreEvent,
  Gender,
  ExploreEventList,
  ProgramEventType,
  ProgramType,
  Venue,
} from '@citruscamps/citrus-client'
import { DefaultDetailsPagination, UnlimitedPagination } from '../constants/pagination'
import { FilterDateRange } from '../interfaces/filter-range'
import { Pagination } from '../interfaces/pagination'
import { generateApiConfig } from '../utils/client-config'
import { toFilterFromQuery, toQueryFromFilter } from '../utils/event-filter-utils'
import { generateItemKey, generateListKey } from '../utils/key-generator'
import { useRequestHandler } from './useRequestHandler'
import { useCookiePreferences } from './useCookiePreferences'
import { useSearchParams } from './useSearchParams'
import { useSearchValue } from './useSearchValue'
import { useToast } from './useToast'
import { useTrackInsightEvent } from './useTrackInsightEvent'

export const DefaultEventFetchProps: EventFetchProps = {
  sort: 'starts_at',
  order: 'ASC',
  filter: {
    type: 'camp',
  },
}

export enum DateRangeEnum {
  'is equal to' = 'is equal to',
  'is between' = 'is between',
  'is after' = 'is after',
  'is before' = 'is before',
  'is upcoming' = 'is upcoming',
  'is past' = 'is past',
}

export type DateRangeType = keyof typeof DateRangeEnum

export interface EventFilterProps {
  age?: number
  min_age?: number
  max_age?: number
  gender?: Gender
  is_online_event?: boolean
  place_name?: string
  program_id?: string
  scheduled?: FilterDateRange
  scheduled_type?: DateRangeType
  tags?: string[]
  type?: ProgramEventType
  program_type?: ProgramType[]
  program_tags?: string[]
}

export interface EventFetchProps {
  sort?: string
  order?: 'ASC' | 'DESC'
  filter: EventFilterProps
}

interface IProps {
  venues?: Venue[]
  hasOnline?: boolean
  search?: string
  pagination?: Pagination
  fetchProps?: EventFetchProps
  enabled?: boolean
  fetchAll?: boolean
  overwriteFilters?: boolean
  ignoreQueries?: boolean
}

interface FetchEvents {
  data: ExploreEvent[]
  error: ErrorResponse | null
  isError: boolean
  isLoading: boolean
  fetchProps: EventFetchProps
  pagination: Pagination
  search: string
  setFetchProps: (value?: EventFetchProps, pagination?: Pagination) => void
  setPagination: Dispatch<Pagination>
  setSearch: Dispatch<string>
}

export const useFetchEvents = ({
  pagination: initialPagination,
  enabled = true,
  venues,
  hasOnline,
  overwriteFilters,
  search: initialSearch = '',
  ...props
}: IProps): FetchEvents => {
  const { requestHandler } = useRequestHandler()
  const { setToast } = useToast()
  const queryClient = useQueryClient()
  const [pagination, setPagination] = useState<Pagination>(
    initialPagination || DefaultDetailsPagination,
  )
  const initialFetchProps: EventFetchProps = {
    ...DefaultEventFetchProps,
    ...props.fetchProps,
    sort: props.fetchProps?.sort || DefaultEventFetchProps.sort,
    order: props.fetchProps?.order || DefaultEventFetchProps.order,
    filter: { ...DefaultEventFetchProps.filter, ...props.fetchProps?.filter },
  }
  const [searchParams, setSearchParams] = useSearchParams<ParsedUrlQueryInput>(
    !overwriteFilters ? toQueryFromFilter(initialFetchProps.filter) : undefined,
  )
  const fetchProps: EventFetchProps = {
    ...initialFetchProps,
    filter: toFilterFromQuery(searchParams),
  }

  const { search, deferSearch, setSearch, isSearching } = useSearchValue(initialSearch, {
    onDefer: () => setPagination(initialPagination || DefaultDetailsPagination),
  })
  const queryKeys = generateListKey({
    type: 'program_event',
    pagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: fetchProps.filter,
    search: deferSearch,
  })

  const {
    data: resp,
    isInitialLoading: isLoading,
    isError,
    error,
  } = useQuery<ExploreEventList, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      const client = new ExploreApi(generateApiConfig())
      const skip = pagination.page * (pagination.limit || 0)
      const response = await requestHandler<ExploreEventList>(
        () =>
          client.findExploreEvents(
            {
              program_id: fetchProps?.filter?.program_id || undefined,
              limit: pagination.limit,
              skip,
              order: fetchProps?.order,
              sort: fetchProps?.sort,
              search: search || undefined,
              age:
                fetchProps?.filter?.age && !isNaN(fetchProps?.filter?.age)
                  ? fetchProps?.filter?.age
                  : undefined,
              gender:
                fetchProps?.filter?.gender && fetchProps.filter.gender.length > 0
                  ? [fetchProps?.filter?.gender]
                  : undefined,
              venue_city: fetchProps?.filter?.place_name || undefined,
              online_event: fetchProps?.filter?.is_online_event,
              type:
                fetchProps?.filter?.type && fetchProps.filter.type.length > 0
                  ? [fetchProps?.filter?.type]
                  : undefined,
              tags:
                fetchProps?.filter?.tags &&
                fetchProps.filter.tags.length > 0 &&
                !fetchProps.filter.tags.includes('')
                  ? fetchProps?.filter?.tags
                  : undefined,
              scheduled_lt:
                fetchProps?.filter?.scheduled?.lt &&
                !fetchProps.filter?.scheduled_type?.includes('is upcoming') &&
                !fetchProps.filter?.scheduled_type?.includes('is past')
                  ? new Date(fetchProps.filter?.scheduled?.lt)
                  : undefined,
              scheduled_gt:
                fetchProps?.filter?.scheduled?.gt &&
                !fetchProps.filter?.scheduled_type?.includes('is upcoming') &&
                !fetchProps.filter?.scheduled_type?.includes('is past')
                  ? new Date(fetchProps.filter?.scheduled?.gt)
                  : undefined,
              scheduled_lte: fetchProps?.filter?.scheduled_type?.includes('is past')
                ? new Date()
                : fetchProps?.filter?.scheduled?.lte
                ? new Date(fetchProps.filter?.scheduled?.lte)
                : undefined,
              scheduled_gte: fetchProps?.filter?.scheduled_type?.includes('is upcoming')
                ? new Date()
                : fetchProps?.filter?.scheduled?.gte
                ? new Date(fetchProps.filter?.scheduled?.gte)
                : undefined,
              age_lte:
                fetchProps?.filter?.max_age && !isNaN(fetchProps?.filter?.max_age)
                  ? fetchProps?.filter?.max_age
                  : undefined,
              age_gte:
                fetchProps?.filter?.min_age && !isNaN(fetchProps?.filter?.min_age)
                  ? fetchProps?.filter?.min_age
                  : undefined,
              program_type:
                fetchProps?.filter?.program_type && fetchProps.filter.program_type.length > 0
                  ? fetchProps?.filter?.program_type
                  : undefined,
              program_tags:
                fetchProps?.filter?.program_tags && fetchProps.filter.program_tags.length > 0
                  ? fetchProps?.filter?.program_tags
                  : undefined,
            },
            { signal },
          ),
        {
          toast: { setToast },
        },
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'program_event',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    { enabled },
  )

  const _setFetchProps = (val?: EventFetchProps) => {
    setSearchParams(toQueryFromFilter(val?.filter))
    setPagination(initialPagination || DefaultDetailsPagination)
  }

  return {
    data: resp?.data || [],
    error,
    fetchProps,
    isError,
    isLoading: isLoading || isSearching,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    search,
    setFetchProps: _setFetchProps,
    setPagination,
    setSearch,
  }
}

interface FetchInfiniteEvents {
  data: ExploreEvent[]
  error: ErrorResponse | null
  fetchProps: EventFetchProps
  isError: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  isLoading: boolean
  isSearching: boolean
  hasNextPage: boolean
  pagination: Pagination
  search: string
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<ExploreEventList, ErrorResponse>>
  setFetchProps: (value?: EventFetchProps) => void
  setSearch: Dispatch<string>
}

export const useFetchInfiniteEvents = ({
  overwriteFilters,
  enabled = true,
  fetchAll = false,
  search: initialSearch = '',
  pagination = DefaultDetailsPagination,
  ignoreQueries = false,
  ...props
}: IProps): FetchInfiniteEvents => {
  const { requestHandler } = useRequestHandler()
  const { handleTrack } = useTrackInsightEvent()
  const initialFetchProps: EventFetchProps = {
    ...DefaultEventFetchProps,
    ...props.fetchProps,
    sort: props.fetchProps?.sort || DefaultEventFetchProps.sort,
    order: props.fetchProps?.order || DefaultEventFetchProps.order,
    filter: { ...DefaultEventFetchProps.filter, ...props.fetchProps?.filter },
  }
  const [filterProps, setFilterProps] = useCookiePreferences<ParsedUrlQueryInput>(
    `event-filter-${props.fetchProps?.filter?.program_id || 'all'}-props`,
    undefined,
    {
      sameSite: false,
    },
  )
  const [searchParams, setSearchParams] = useSearchParams<ParsedUrlQueryInput>(
    !overwriteFilters ? filterProps || toQueryFromFilter(initialFetchProps.filter) : undefined,
  )
  const fetchProps: EventFetchProps = {
    ...initialFetchProps,
    filter: !ignoreQueries ? toFilterFromQuery(searchParams) : initialFetchProps.filter,
  }
  const { search, deferSearch, setSearch, isSearching } = useSearchValue(initialSearch, {
    onDefer: async (searchTerm) => {
      if (typeof searchTerm !== 'string') return
      await Promise.allSettled([
        handleTrack({
          type: 'search',
          props: {
            search_term: searchTerm,
          },
        }),
      ])
    },
  })
  const queryKeys = generateListKey({
    type: 'program_event',
    pagination: UnlimitedPagination,
    sort: fetchProps?.sort,
    order: fetchProps?.order,
    query: fetchProps?.filter,
    search: deferSearch || initialSearch,
  })
  const {
    data,
    error,
    isError,
    isFetching,
    isFetchingNextPage,
    isInitialLoading: isLoading,
    fetchNextPage,
    hasNextPage = false,
  } = useInfiniteQuery<ExploreEventList, ErrorResponse>(
    queryKeys,
    async ({ signal, pageParam: pg }) => {
      pg = pg || pagination
      const client = new ExploreApi(generateApiConfig())
      const skip = pg.limit ? pg.page * pg.limit : undefined
      const resp: ExploreEventList = await requestHandler<ExploreEventList>(() =>
        client.findExploreEvents(
          {
            program_id: fetchProps?.filter?.program_id || undefined,
            limit: pagination.limit,
            skip,
            order: fetchProps?.order,
            sort: fetchProps?.sort,
            search: search || undefined,
            age:
              fetchProps?.filter?.age && !isNaN(fetchProps?.filter?.age)
                ? fetchProps?.filter?.age
                : undefined,
            gender:
              fetchProps?.filter?.gender && fetchProps.filter.gender.length > 0
                ? [fetchProps?.filter?.gender]
                : undefined,
            venue_city: fetchProps?.filter?.place_name || undefined,
            online_event: fetchProps?.filter?.is_online_event,
            type:
              fetchProps?.filter?.type && fetchProps.filter.type.length > 0
                ? [fetchProps?.filter?.type]
                : undefined,
            tags:
              fetchProps?.filter?.tags &&
              fetchProps.filter.tags.length > 0 &&
              !fetchProps.filter.tags.includes('')
                ? fetchProps?.filter?.tags
                : undefined,
            scheduled_lt:
              fetchProps?.filter?.scheduled?.lt &&
              !fetchProps.filter?.scheduled_type?.includes('is upcoming') &&
              !fetchProps.filter?.scheduled_type?.includes('is past')
                ? new Date(fetchProps.filter?.scheduled?.lt)
                : undefined,
            scheduled_gt:
              fetchProps?.filter?.scheduled?.gt &&
              !fetchProps.filter?.scheduled_type?.includes('is upcoming') &&
              !fetchProps.filter?.scheduled_type?.includes('is past')
                ? new Date(fetchProps.filter?.scheduled?.gt)
                : undefined,
            scheduled_lte: fetchProps?.filter?.scheduled_type?.includes('is past')
              ? new Date()
              : fetchProps?.filter?.scheduled?.lte
              ? new Date(fetchProps.filter?.scheduled?.lte)
              : undefined,
            scheduled_gte: fetchProps?.filter?.scheduled_type?.includes('is upcoming')
              ? new Date()
              : fetchProps?.filter?.scheduled?.gte
              ? new Date(fetchProps.filter?.scheduled?.gte)
              : undefined,
            age_lte:
              fetchProps?.filter?.max_age && !isNaN(fetchProps?.filter?.max_age)
                ? fetchProps?.filter?.max_age
                : undefined,
            age_gte:
              fetchProps?.filter?.min_age && !isNaN(fetchProps?.filter?.min_age)
                ? fetchProps?.filter?.min_age
                : undefined,
            program_type:
              fetchProps?.filter?.program_type && fetchProps.filter.program_type.length > 0
                ? fetchProps?.filter?.program_type
                : undefined,
            program_tags:
              fetchProps?.filter?.program_tags && fetchProps.filter.program_tags.length > 0
                ? fetchProps?.filter?.program_tags
                : undefined,
          },
          { signal },
        ),
      )
      return resp
    },
    {
      getNextPageParam: (resp: ExploreEventList): Pagination | undefined => {
        if (!resp.page_count || resp.page < resp.page_count - 1) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page + 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      getPreviousPageParam: (resp: ExploreEventList): Pagination | undefined => {
        if (!resp.page_count || resp.page <= resp.page_count) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page - 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      enabled,
    },
  )

  const _setFetchProps = (val?: EventFetchProps) => {
    const params = toQueryFromFilter(val?.filter)
    setFilterProps(params)
    setSearchParams(params)
  }

  if (!(isFetching || isFetchingNextPage || isLoading) && hasNextPage && fetchAll) {
    fetchNextPage()
  }

  const resp = [...(data?.pages || [])].pop()

  return {
    data: (data?.pages || []).reduce<ExploreEvent[]>((list, p) => [...list, ...p.data], []) || [],
    error,
    fetchProps,
    hasNextPage,
    isError,
    isFetching,
    isFetchingNextPage,
    isLoading,
    isSearching,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    search,
    fetchNextPage,
    setFetchProps: _setFetchProps,
    setSearch,
  }
}
