import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { ExploreProgram } from '@citruscamps/citrus-client'
import { ROUTES } from '../../constants/routes'
import { getProgramCardImageStyle } from '../../utils/branding-utils'
import Image from 'next/image'

interface IProps {
  program: ExploreProgram
}

export const ProgramCard = ({ program }: IProps) => {
  const router = useRouter()
  const types: string = (program.tags.length > 0 ? program.tags : program.types).join(', ')
  router.prefetch(ROUTES.EXPLORE.replace('[program_id]', program.id))
  return (
    <Link
      href={{
        pathname: ROUTES.EXPLORE,
        query: { program_id: program.id },
      }}
      className="text-dark text-decoration-none"
      passHref
    >
      <div
        className="card program-card shadow border-0"
        style={{ borderRadius: '1rem', cursor: 'pointer' }}
      >
        <div className="card-img-top d-flex" style={getProgramCardImageStyle(program?.preferences)}>
          <div className="m-auto p-4">
            <ProgramBanner
              program={program}
              sizes="(max-width: 768px) 100vw, (max-width: 992px) 50vw, 33vw"
            />
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <h5 className="card-title mb-2">{program.name}</h5>
              <p className="text-primary text-capitalize small mb-0">{types}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

interface IBannnerProps extends IProps, React.HTMLAttributes<HTMLDivElement> {
  sizes: string
}

export const ProgramBanner = ({ program, sizes, ...props }: IBannnerProps) => (
  <div {...props}>
    {!!program?.preferences?.checkout_background_url && (
      <Image
        alt={program?.name}
        src={program?.preferences?.checkout_background_url}
        fill
        sizes={sizes}
        style={{
          objectFit: 'fill',
        }}
      />
    )}
    {!!program?.preferences?.checkout_overlay_color &&
      !!program?.preferences?.checkout_background_url && (
        <div
          className="position-absolute w-100 h-100 left-0 top-0"
          style={{
            backgroundColor:
              program?.preferences?.checkout_overlay_color &&
              program?.preferences?.checkout_background_url
                ? `#${program?.preferences?.checkout_overlay_color}`
                : undefined,
            opacity: program.preferences?.checkout_overlay_opacity || 0,
          }}
        ></div>
      )}
    {!!program?.preferences?.logo && (
      <Image
        alt={program?.name}
        src={program?.preferences?.logo}
        fill
        sizes={sizes}
        style={{
          objectFit: 'contain',
        }}
      />
    )}
  </div>
)
